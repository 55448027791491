import Composition from '../types/composition.type';
import { AdobeLayer } from '../types/layer.type';
import Page from '../types/page.type';
import Template, { TemplateData } from '../types/template.type';
import { getTemplateData } from './data.helpers';

export type ItemsInLayers = Record<AdobeLayer['key'], Composition[] | Page[]>;
export type FilteredItems = Composition['key'][] | Page['key'][];

class AdobeHelpers {
    /**
     * Get all layers from all compositions or pages.
     * @param layers - Layers grouped per composition or page.
     * @returns All layers in a flat array.
     */
    static getAllLayers = (layers?: Template['layers']): AdobeLayer[] => {
        if (layers === undefined) layers = getTemplateData<Template['layers']>('layers');
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        return (
            Object.keys(layers)
                // Get all layers from all compositions or pages and flatten the array.
                .reduce((allLayers, key) => {
                    const itemLayers = layers[key];

                    itemLayers.forEach((layer: AdobeLayer) => {
                        allLayers.push(layer);
                    });

                    return allLayers;
                }, [] as AdobeLayer[])
                // Filter out duplicate layers.
                .reduce((uniqueLayers, currentLayer) => {
                    const foundLayer = (() => {
                        if (templateType === 'dynamicAfterEffects') {
                            return uniqueLayers.find((layer) => layer['originalName'] === currentLayer['originalName']);
                        }

                        return uniqueLayers.find((layer) => layer['title'] === currentLayer['title']);
                    })();

                    if (!foundLayer) {
                        uniqueLayers.push(currentLayer);
                    }

                    return uniqueLayers;
                }, [] as AdobeLayer[])
        );
    };

    /**
     * Get all compositions or pages where the layer is in.
     * @param allLayers - All the layers in a flat array.
     * @param layers - Layers grouped per item.
     * @param filteredItems - The current filter.
     * @param items - The items to filter based on the template type.
     * @returns Grouped items in layers.
     */
    static getItemInLayers = (
        allLayers: AdobeLayer[],
        items: Template['compositions'] | Template['pages'] = [],
        layers?: Template['layers']
    ): ItemsInLayers => {
        if (layers === undefined) layers = getTemplateData<Template['layers']>('layers');
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        return allLayers.reduce((layerInItems, layer) => {
            layerInItems[layer.key] = [];

            items.forEach((item) => {
                layers[item.key].forEach((itemLayer: AdobeLayer) => {
                    if (templateType === 'dynamicAfterEffects') {
                        const alreadyExists = layerInItems[layer.key].includes(item);

                        if (!alreadyExists && (itemLayer.key === layer.key || itemLayer.originalName === layer.originalName)) {
                            layerInItems[layer.key].push(item);
                        }
                    }

                    if (templateType === 'dynamicInDesign') {
                        const alreadyExists = layerInItems[layer.key].includes(item);

                        if (!alreadyExists && itemLayer.title === layer.title) {
                            layerInItems[layer.key].push(item);
                        }
                    }
                });
            });

            return layerInItems;
        }, {} as ItemsInLayers);
    };
}

export { AdobeHelpers };
